import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { IPage, ISlice, IOnOffType } from "../types/"

// Styles
import colors from "../styles/colors"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import { renderSlice } from "../components/helpers/slice"
import SEO from "../components/layout/SEO"
import HowWeWorkHeader from "../components/HowWeWorkHeader"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface IProps {
  data: IPage
  slices: any
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.black,
  },
}

const AboutPage = ({ data, slices }: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.white)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <Wrapper>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      <HowWeWorkHeader
        introTitle={data.introTitle}
        introCopyHtml={data.introCopy.html}
      />

      {slices &&
        slices.length &&
        slices.map((slice: ISlice) => {
          let props
          const hasSpacing = slice.data.hasSpacing

          if (slice.type === "list") {
            props = {
              isSpaced: "off" as IOnOffType,
            }
          } else {
            props = {
              // Assign a default
              isSpaced: "on" as IOnOffType,
              // Override if condition passes
              ...(hasSpacing === "off" && {
                isSpaced: slice.data.hasSpacing,
              }),
            }
          }

          return (
            <Section key={slice.id} {...props}>
              {renderSlice(slice)}
            </Section>
          )
        })}
    </Wrapper>
  )
}

// Utility styles
const Section = styled.section<{ isSpaced?: IOnOffType }>`
  background: #1e1e1e;
  color: white;
  ul > div > div {
    height: unset !important;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

// Main styles
const Wrapper = styled.div`
  color: ${colors.black};
`
export default AboutPage
