import React from "react"
import styled from "styled-components"
import { Col } from "./layout/Grid"
import { mq } from "../styles/utils/mq"
import AnimatedScrollWrapper from "./animation/AnimatedScrollWrapper"
interface IProps {
  introTitle: string
  introCopyHtml: string
}

const HowWeWorkHeader = ({ introTitle, introCopyHtml }: IProps) => (
  <Col xxs={9} xxsOffset={0} m={9} mOffset={0}>
    <AnimatedScrollWrapper>
      <Texts>
        <div>{introTitle}</div>
        <IntroCopyText
          dangerouslySetInnerHTML={{ __html: introCopyHtml }}
        ></IntroCopyText>
      </Texts>
    </AnimatedScrollWrapper>
  </Col>
)

const Texts = styled.div`
  color: black;
  line-height: 1.125;
  font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (768 - 320)));
  letter-spacing: -0.27px;
  padding-top: 25%;

  ${mq.from.XS`
    padding-left:8px;
  `}

  ${mq.from.L`
    padding-left:16px;
  `}
`
const IntroCopyText = styled.div`
  > p {
    font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (768 - 320)));
  }
`
export default HowWeWorkHeader
