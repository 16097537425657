import React from "react"
import { graphql } from "gatsby"

// Types
import { ISlice } from "../types/"

// Utils
import { formatPrismicData } from "../utils/helpers/prismicHelpers"

// Components
import AboutPage from "../pageViews/aboutPageView"

const AboutPageContainer = ({ data }: any) => {
  if (!data.aboutPage) {
    return null
  }

  let slices
  const rawData = data.aboutPage.data

  const formattedData = {
    metaTitle: rawData.metaTitle,
    metaDescription: rawData.metaDescription,
    pageTitle: rawData.page_title,
    introTitle: rawData.intro_title,
    introCopy: rawData.intro_copy,
  }

  if (rawData.slices) {
    // !: Make better util... formatPrismicSlice, formatPrismicSlices/Data
    slices = rawData.slices
      .filter((slice: any) => slice.type && slice)
      .map((slice: any) => formatPrismicData(slice))
      .filter((slice: ISlice) => slice !== null)
  }

  return <AboutPage data={formattedData} slices={slices} />
}

export const query = graphql`
  query GetAboutPage {
    aboutPage: prismicAboutPage {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        intro_title
        intro_copy {
          html
        }
        slices: body {
          ... on PrismicAboutPageBodyTextRoundedImage {
            type: slice_type
            id
            primary {
              title {
                html
              }
              copy
              heading
              contact_name
              contact_title
              underlined_paragraph
              rounded_image {
                url
              }
            }
          }
          ... on PrismicAboutPageBodyTextMedia {
            type: slice_type
            id
            primary {
              text_media_title {
                raw {
                  text
                }
              }
              text_media_copy {
                html
              }
              text_media_media {
                kind
                link_type
                url
                alt: name
              }
              example_link_title
              example_link_text_1
              example_link_text_2
              example_link_text_3
              example_link_url_1 {
                url
              }
              example_link_url_2 {
                url
              }
              example_link_url_3 {
                url
              }
              text_media_media_alignment
              text_media_media_padding
              text_media_has_spacing
              text_media_top_border
              text_media_bottom_border
            }
          }
        }
      }
    }
  }
`

export default AboutPageContainer
